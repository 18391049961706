import React from 'react';
import { GraphQLClient } from 'graphql-request';

import { navigationInfo, dynamicPageSlug } from 'components/utils';
import Header from 'components/__dynamic/header';
import Footer from 'components/__dynamic/footer';
import Layout from 'components/Layout';

import { PageBuilder } from 'components/__dynamic/pagebuilder';
import { GradientWrapper } from 'components/LayoutUtilities';

const hygraphClient = new GraphQLClient(process.env.NEXT_PUBLIC_GRAPHCMS);

export async function getStaticProps() {
  const { navigation } = await hygraphClient.request(navigationInfo);

  const { page } = await hygraphClient.request(dynamicPageSlug, {
    slug: navigation.landingPage.slug,
  });

  return {
    props: { page, navigation },
    revalidate: 2,
  };
}

export default function Page({ page, navigation }) {
  return (
    <Layout metaData={page?.ogData}>
        <Header navigation={navigation} />
      {page?.components?.map((component, id) => (
        <PageBuilder key={id} component={component} />
      ))}
      <Footer navigation={navigation} />
    </Layout>
  );
}
